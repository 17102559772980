export enum MakeupType {
  MAKEUP = 'MAKEUP',
  LESSON = 'LESSON',
  TRIAL_WEDDING = 'TRIAL_WEDDING',
  WEDDING = 'WEDDING',
  GROOM = 'GROOM',
  BREAK = 'BREAK',
  MODEL = 'MODEL',
  RESERVATION = 'RESERVATION',
  RESERVE_LIST = 'RESERVE_LIST',
}

export interface MakeupData {
  type: MakeupType;
  _id: string,
  firstName: string,
  lastName: string,
  localization: string,
  source: string,
  date: Date,
  time: string,
  phone: string,
  notes: string,
  peopleCount: number,
  user: string,
  isSecondTermSent: boolean,
  isConfirmed: boolean,
  isRecurring: boolean,
  instagram?: string;
  tags?: string[];
  photos?: string[];

  is_deleted: boolean;
  createdAt: Date,
  updatedAt: Date,
}

export interface MakeupsState {
  makeups: MakeupData[],
  makeup: MakeupData,
  recurring: MakeupData[],
  isLoading: true | false,
  count: number,
  locationKey?: string,
}

export interface CreateMakeupFormData {
  _id?: string,
  type: MakeupType;
  firstName: string,
  lastName: string,
  localization: string,
  source: string,
  date: string,
  time: string,
  phone: string,
  notes: string,
  peopleCount: string,
  isSecondTermSent: boolean,
  isConfirmed: boolean,
  isRecurring: boolean,
  instagram?: string,
}

export interface CreateMakeupDTO extends CreateMakeupFormData {
  user: string,
}

export type UpdateMakeupDTO = Partial<CreateMakeupFormData>;

export type FindAllParams = Partial<{
  archived: boolean,
  deleted: boolean,
  recurring: boolean,
}>

export interface MakeupDataDTO {
  data: MakeupData[];
  count: number;
};
