import { MakeupsState, MakeupData } from "./makeups.interfaces";

const state: MakeupsState = {
  makeup: {} as MakeupData,
  makeups: [],
  recurring: [],
  isLoading: false,
  count: 0,
  locationKey: '',
}

export default state;
