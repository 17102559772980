import React from 'react'
import {withSnackbar, WithSnackbarProps} from 'notistack';
import {navigate, RouteComponentProps} from '@reach/router';
import Layout from '../components/Layout/Layout';
import dayjs from '../libs/dayjs';
import Form from '../components/Form/Form';
import {connect} from 'react-redux';
import {CreateMakeupFormData, MakeupData, MakeupType} from '../redux/modules/makeups/makeups.interfaces';
import {createMakeup, CreateMakeup} from '../redux/modules/makeups/makeups.thunks';
import {RootState} from '../redux';

const defaultValues = {
  type: MakeupType.MAKEUP,
  firstName: '',
  lastName: '',
  localization: 'Poznań',
  source: 'Olx',
  phone: '+48',
  date: dayjs().format('YYYY-MM-DD'),
  time: '10:00',
  notes: '',
  peopleCount: '1',
  isSecondTermSent: false,
  isConfirmed: false,
  isRecurring: false,
  instagram: '',
}

interface CreatePageReduxProps {
  isLoading: boolean,
  createMakeup: CreateMakeup,
}

const CreatePage: React.FunctionComponent<RouteComponentProps & WithSnackbarProps & CreatePageReduxProps> = ({
  enqueueSnackbar, createMakeup, isLoading, location,
}) => {
  const submitForm = async (values: CreateMakeupFormData) => {
    try {
      let makeup: MakeupData;
      if (values.type === MakeupType.BREAK || values.type === MakeupType.MODEL) {
        makeup = await createMakeup({
          ...values,
          lastName: 'Przerwa',
          localization: 'Studio',
          peopleCount: '0',
          source: 'Myszka',
          ...(values.isRecurring ? {
            time: '00:00',
          } : {})
        });
      } else {
        makeup = await createMakeup({
          ...values,
          isRecurring: false,
        });
      }

      enqueueSnackbar((() => {
        switch (makeup.type) {
          case MakeupType.TRIAL_WEDDING:
            return 'Makijaż próbny ślubny został dodany';
          case MakeupType.GROOM:
            return 'Poprawka pana młodego została dodana';
          case MakeupType.WEDDING:
            return 'Makijaż ślubny został dodany';
          case MakeupType.MAKEUP:
            return 'Makijaż został dodany';
          case MakeupType.RESERVATION:
            return 'Rezerwacja została dodana';
          case MakeupType.LESSON:
            return 'Lekcja makijażu została dodana';
          case MakeupType.BREAK:
            return 'Przerwa została dodana';
          case MakeupType.MODEL:
            return 'Modelka została dodana';
        }
      })(),{
        variant: 'success',
      });

      if (location && location.state && (location.state as any).fromArchive) {
        navigate('/archive');
        return;
      }
      navigate('/', {
        state: {
          makeup,
        },
      });
    } catch (e) {
      enqueueSnackbar('Wystąpił błąd', { variant: 'error'});
    }
  };

  return (
    <Layout>
      <Form
        isLoading={isLoading}
        onFormSubmit={submitForm}
        onFormCancel={() => navigate('/')}
        formSubmitButtonText="Dodaj"
        {...defaultValues}
      />
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => ({
  isLoading: state.makeups.isLoading,
})

const mapDispatchToProps = {
  createMakeup,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSnackbar(CreatePage));
