import Paper from "@mui/material/Paper";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import styles from './PhotoForm.module.css';
import {Box, Button, Grid, IconButton, Typography} from "@mui/material";
import { MuiFileInput } from 'mui-file-input'
import {useState} from "react";
import Close from "@mui/icons-material/Close";
import {deleteFile, uploadFiles} from "../../redux/modules/makeups/makeups.thunks";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux";

const PhotoForm = () => {
    const { _id, photos } = useSelector((state: RootState) => state.makeups.makeup);
    const dispatch = useDispatch();
    const [files, setFiles] = useState<File[] | undefined>(undefined)

    const handleChange = (newFile: File[] | undefined) => {
        setFiles(newFile)
    }

    const inputText = (file: File[]) => {
        return `Załączono ${file.length} zdjęć`;
    }

    const removeFile = (toRemove: File) => {
        setFiles(oldFiles => oldFiles?.filter(file => toRemove.name !== file.name))
    }

    const removeFileFromMakeup = async (toRemove: string) => {
        await dispatch(
            deleteFile(
                _id,
                toRemove,
            )
        )
    }

    const handleSubmit = async () => {
        const form = new FormData();

        if (!files) {
            return;
        }

        files.forEach((file: File) => {
            form.append('files', file);
        });

        await dispatch(
            uploadFiles(
                _id,
                form,
            )
        )

        setFiles([])
    }

    return (
        <Paper className={styles.paper}>
            <Typography variant={"h5"}>Zdjęcia</Typography>
            <Box sx={{ marginBlock: 2, display: "flex", alignItems: "center", gap: 2 }}>
                <MuiFileInput
                    multiple
                    value={files}
                    onChange={handleChange}
                    getInputText={inputText}
                    placeholder={"Wybierz pliki"}
                    InputProps={{
                        inputProps: {
                            accept: 'image/*',
                        },
                        startAdornment: <AttachFileIcon />
                    }}
                />
                <Button
                    type="button"
                    color="secondary"
                    onClick={() => { setFiles([]) }}
                >
                    Usuń
                </Button>
                <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                >
                    Zapisz zdjęcia
                </Button>
            </Box>
            <div>
                {!!files?.length && <Typography variant={"h6"}>Nowe zdjęcia</Typography>}
                <Grid container spacing={2}>
                    {files?.map((file: File) => (
                        <Grid item xs={6} sm={4} sx={{ position: 'relative' }}>
                            <IconButton
                                sx={{ position: "absolute", top: 20, right: 4, background: '#E6BDFF' }}
                                onClick={() => removeFile(file)}
                            >
                                <Close />
                            </IconButton>

                            <img src={URL.createObjectURL(file)} style={{ objectFit: 'contain', width: '100%' }} />
                        </Grid>
                    ))}
                </Grid>
                {!!photos?.length && <Typography variant={"h6"}>Aktualne zdjęcia</Typography>}
                <Grid container spacing={2}>
                    {photos?.map(url => (
                        <Grid item xs={6} sm={4} sx={{ position: 'relative' }}>
                            <IconButton
                                sx={{ position: "absolute", top: 20, right: 4, background: '#E6BDFF' }}
                                onClick={() => removeFileFromMakeup(url)}
                            >
                                <Close />
                            </IconButton>

                            <img src={`${process.env.REACT_APP_PHOTOS_URL}/${url}`} style={{ objectFit: 'contain', width: '100%' }} />
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Paper>
    )
}

export default PhotoForm;
