import { createSlice } from "redux-starter-kit";
import state from "./makeups.state";
import {
  setAllReducer,
  setOneReducer,
  updateReducer,
  removeReducer,
  setIsLoadingReducer,
  addNewMakeupReducer,
  setLocationKeyReducer,
  setAllRecurringReducer
} from "./makeups.reducers";

const makeupsSlice = createSlice({
  slice: 'makeups',

  initialState: state,

  reducers: {
    setAll: setAllReducer,
    setAllRecurring: setAllRecurringReducer,
    setOne: setOneReducer,
    update: updateReducer,
    remove: removeReducer,
    addNewMakeup: addNewMakeupReducer,
    setIsLoading: setIsLoadingReducer,
    setLocationKey: setLocationKeyReducer,
  }
})
// Extract the action creators object and the reducer
const { actions, reducer, selectors } = makeupsSlice
// Extract and export each action creator by name
export const { setAll, setOne, update, remove, addNewMakeup, setIsLoading, setLocationKey, setAllRecurring } = actions

export const { getMakeups } = selectors;
// Export the reducer, either as a default or named export
export default reducer
